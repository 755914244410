<script lang="ts" setup>
import type { GroupedSources, Main, Source } from '~/types/banners'

defineProps<{
  bannerName: string
  main: Main
  sources: Source[]
}>()

function groupSourcesByType(sources: Source[]): GroupedSources {
  return sources.reduce<GroupedSources>(
    (acc: GroupedSources, x: Source): GroupedSources => {
      const { density, type, url } = x

      if (!acc[type]) {
        acc[type] = []
      }

      acc[type].push({ density, url })

      return acc;
    },
    {} as GroupedSources
  );
}
</script>

<template>
  <picture>
    <source
      v-for="(groupedSources, type) in groupSourcesByType(sources)"
      :key="type"
      :srcset="groupedSources.map((x: Source): string => `${x.url} ${x.density}`).join(', ')"
      :type="type as string"
    >
    <img
      :alt="bannerName"
      class="size-full object-cover"
      fetchpriority="high"
      :height="main.height"
      loading="lazy"
      :src="main.url"
      :width="main.width"
    >
  </picture>
</template>
