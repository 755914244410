<script lang="ts" setup>
import type { SwiperOptions } from 'swiper'
import { useModal } from 'vue-final-modal'

import CreateSubscriber from '~/components/modal-windows/CreateSubscriber.vue'
import type { BannerData } from '~/types/banners'

const props = defineProps<{
  bannersData: BannerData[]
}>()

const speedInMs = 300
const galleryOptions: SwiperOptions = {
  autoplay: { delay: 5 * 1000, disableOnInteraction: false, pauseOnMouseEnter: true },
  breakpoints: {
    768: { slidesPerGroup: 1, slidesPerView: 1 },
    0: { slidesPerGroup: 1, slidesPerView: 1.15, spaceBetween: 8 }
  },
  lazy: true,
  spaceBetween: 24,
  speed: speedInMs,
  on: {
    transitionEnd (swiper) {
      const { activeIndex, previousIndex, slides } = swiper
      const slidesCount = slides.length
      swiper.params.speed = slidesCount > 2 && activeIndex === slidesCount - 1 && previousIndex === slidesCount - 2
        ? speedInMs * (slidesCount - 1)
        : speedInMs
    }
  }
}

const router = useRouter()

const isGallery = computed(() => props.bannersData.length > 1)

async function onBannerClick (bannerData: BannerData): Promise<void> {
  const { action, actionParameters, path, url } = bannerData.attributes

  if (action) {
    if (
      action === 'create-subscriber'
      && typeof actionParameters?.modalWindowTitle === 'string'
      && typeof actionParameters?.subscriptionFormId === 'string'
    ) {
      await useModal({
        component: CreateSubscriber,
        attrs: {
          modalWindowTitle: actionParameters.modalWindowTitle,
          subscriptionForm: actionParameters.subscriptionFormId
        }
      }).open()
    }
  } else if (path) {
    await router.push(path)
  } else if (url) {
    await navigateTo(url, { open: { target: '_blank' } })
  }
}
</script>

<template>
  <section v-if="bannersData.length">
    <div class="container gallery-container">
      <div class="flex">
        <div class="relative w-full overflow-hidden md:h-[364px] md:w-[1016px]">
          <Gallery :is-gallery="isGallery" :options="galleryOptions">
            <div
              v-for="x in bannersData"
              :key="x.id"
              class="swiper-slide cursor-pointer"
              :class="{ 'max-1-slide': isGallery }"
              @click="onBannerClick(x)"
            >
              <div
                class="
                  relative w-full overflow-hidden rounded-2xl bg-[#e7e7e7] pb-[56.25%]
                  md:h-[364px] md:w-[1016px] md:rounded-3xl md:pb-0
                "
              >
                <BannerPicture
                  :banner-name="x.attributes.name"
                  class="absolute hidden size-full md:inline-block"
                  :main="x.meta.images.desktop.main"
                  :sources="x.meta.images.desktop.sources"
                />
                <BannerPicture
                  :banner-name="x.attributes.name"
                  class="absolute inline-block size-full md:hidden"
                  :main="x.meta.images.mobile.main"
                  :sources="x.meta.images.mobile.sources"
                />
              </div>
            </div>
          </Gallery>
        </div>
        <BannerCards class="ml-4 hidden w-[328px] md:block" />
      </div>
    </div>
  </section>
</template>
